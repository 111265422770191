<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->

    <b-overlay :show="formShow" rounded="sm" no-fade>
      <b-row>
        <b-col cols="12">
          <!-- Vehiclle Image and Name -->
          <b-card>
            <b-card-body class="mt-0 pt-0">
              <b-row class="justify-content-between mb-1">
                <h1></h1>
                <div>
                  <b-button v-show="$Can('vehicle_edit_btn')" class="mr-1" @click="editVehicle" variant="primary">
                    Edit
                  </b-button>
                </div>
              </b-row>

              <b-row>
                <table class=" table  table-bordered table-hover">
                  <tbody class="text-left">
                    <tr>
                      <td class="item-price bg-secondary text-white w-25">Title</td>
                      <td class="w-75">{{ hotelData.title }}</td>
                    </tr>

                    <tr>
                      <td class="item-price bg-secondary text-white">Price</td>
                      <td>{{ hotelData.price }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
('bootstrap-vue');
import { BModal, BAvatar, BCard, BCardBody, BRow, BCol, BImg, BLink, BButton, BAlert, BCardHeader, BCardTitle, VBToggle, BTabs, BTab, BCardText } from 'bootstrap-vue';
import moment from 'moment';
import hotelStoreModule from '../hotelStoreModule';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';

// import { avatarText } from '@core/utils/filter'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,
    BAvatar,
    BModal,
    BCardBody,
    BImg,
    BCardHeader,
    BCardTitle,
    BTabs,
    BCardText,
    BTab,
  },
  setup() {
    const HOTEL_APP_STORE_MODULE_NAME = 'hotel';

    // Register module
    if (!store.hasModule(HOTEL_APP_STORE_MODULE_NAME)) store.registerModule(HOTEL_APP_STORE_MODULE_NAME, hotelStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOTEL_APP_STORE_MODULE_NAME)) store.unregisterModule(HOTEL_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var hotelData = {
      id: null,
      title: null,
      price: null,
    };

    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      hotelData: hotelData,
      baseURL: store.state.app.baseURL,
      hotelId: null,
      formShow: false,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  created() {
    this.getHotelById();
  },

  methods: {
    editVehicle() {
      router.push({ name: 'hotel-edit', params: { id: router.currentRoute.params.id } });
    },

    getHotelById() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.hotelId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('hotel/fetchHotel', { id: router.currentRoute.params.id })
          .then((response) => {
            if (response.status == 200) {
              this.hotelData = response.data;
              this.formShow = false;
            } else {
              router.push({ name: 'error-404' });
            }
          })
          .catch((error) => {
            router.push({ name: 'error-404' });
          });
      } else {
        this.formShow = false;
      }
    },

    dateFormat(val) {
      if (val != null && val != '') {
        moment.locale('en-US');
        return moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY');
      } else {
        return 'N/A';
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.icon {
  stroke: black;
  fill: none;
  width: 36px;
  height: 36px;
  stroke-width: 0.6666666666666666;
}

.user-title {
  width: 100%;
  height: 100%;

  font-weight: bold;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  margin: auto;
}

.user-badge {
  text-align: center;
  vertical-align: middle;

  width: 50%;
  align-content: center;
}
</style>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>
